import React from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom';
import { useTenant } from '../../hooks';

const QrPrintableService = ({item}) => {
    const params = useParams();
    const _qrValue = `${process.env.REACT_APP_RATING_WEB_USER_BASE_URL}/${params.tenant}/${item._id}` ;
    const {currentCompany} = useTenant();

    return (
        <div id={`printablediv-${item._id}`} className='hidden flex flex-col justify-between    ' >
                <div className='flex justify-between gap-12 '>
                    <div className='flex-1 text-start'><img src={currentCompany?.logo}  className='w-fit max-w-48 ' /></div>
                    <div className='flex-1 pt-10'>
                        <div className='text-end  text-2xl font-bold '> للتقييم والشكاوي والاقتراحات </div>
                        <div className='text-end  text-lg font-bold '>For evaluation, complaints and suggestions</div>
                    </div>
                </div>
                <div className='flex-1 p-5  '>
                    <div className='w-full pt-5 flex flex-col justify-center items-center '>
                        <div className='text-center p-8 text-3xl font-bold'>{item.serviceName}</div>
                        <div className='w-full flex flex-col justify-center items-center  rounded bg-slate-100'>
                            <div className='w-5/6  flex justify-between items-between   px-2  font-bold '>
                                <span className='flex-1'>Scan the code</span>
                                <span className='flex-1 text-end'>امسح الكود </span>
                            </div>
                            <QRCode
                                className='w-75'
                                size={128}
                                style={{ height: "auto", maxWidth: "80%", width: "80%"  }}
                                value={_qrValue}
                                viewBox={`0 0 128`}
                                />
                            <div className='p-1 flex justify-between  '>
                                <span>Powerd By www.TrustedBox.app</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default QrPrintableService