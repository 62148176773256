import {useEffect, useState} from 'react'
import {  useTranslation } from 'react-i18next';
import { useCurrent, useFetch } from '../hooks';
import { useParams } from 'react-router-dom';
import DashboradNavBar from '../components/dashborad/DashboradNavBar';
import MonthlyLineChart from '../components/dashborad/MonthlyLineChart';
import { MwSelector } from '../components/ui';

const DashBoard = () => {

    const [t] = useTranslation('global')
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    // let salesCurrentYear = 0;
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    // const currentYeay = date.getFullYear();
     // const Sales Months groups
    const [serviceSelected,setServiceSelected] = useState();
    const [ratingByService,setRatingByService] = useState([]);
    const [ratingByServiceLoading,setRatingByServiceLoading] = useState(true);
    const tenantUsername = useParams()?.tenant;
    const [servicesRate,setServicesRate] = useState(0);
    const [employeesRate,setEmployeesRate] = useState(0);
    
    const {data:servicesResult,loading:servicesLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:ratingResult,loading:ratingLoading,fetchData} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${serviceSelected?.value?._id}`,false);
    const {data:ratingResultALL,loading:ratingLoadingAll} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/${currentTenantId}/${currentCompanyId}/${currentBranchId}/0`);


    const ratingByServiceHandler = async () => {
        // const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/month-group/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
        // const sum = res.data;
        setRatingByService([
            // "3",
            // "4",
            // "4",
            // "5",
            // "2",
            // "5",
            // "5",
            // "5",
            // "4",
            // "5",
            // "5",
            // "5",
        ])
        setRatingByServiceLoading(false)
    }


    useEffect(() =>{
        ratingByServiceHandler();
    },[]);

    useEffect(() =>{
        if(servicesResult?.data.length > 0){
            let _rand = Math.round(Math.random() * (servicesResult?.data?.length - 0) + 0);
            if(_rand >= servicesResult?.data?.length ){
                _rand = _rand -1;
            }
            console.log('_rand',_rand);
            const _selected = servicesResult?.data[_rand]
            setServiceSelected({label:_selected?.serviceName, value:_selected});
            console.log('servicesResult => ',servicesResult);
        }
    },[servicesResult]);

    useEffect(() =>{
        if(serviceSelected){
            fetchData();
        }
        console.log('serviceSelected',serviceSelected)
    },[serviceSelected]);

    useEffect(() =>{
        if(servicesResult && ratingResult){
            console.log('ratingResult => ',ratingResult);
            let _monthlyRating=[];
            
            [1,2,3,4,5,6,7,8,9,10,11,12].forEach(month =>{
                let sumMonthly = 0;
                ratingResult.data.map( res => {
                    const _date = new Date(res.createdAt);
                    if( _date.getMonth() + 1 === month ){
                        if(res.ratingService){
                            sumMonthly += parseInt(res.ratingService || 0)
                        }
                    }
                })
                _monthlyRating.push(sumMonthly)
            })
            console.log( '_monthlyRating => ',_monthlyRating)
            setRatingByService(_monthlyRating)
            
        }
    },[ratingResult,servicesResult]);

    useEffect(() =>{
        if(ratingResultALL){
            console.log( 'ratingResultALL',ratingResultALL );

            const _ratingServiceSum = ratingResultALL?.data?.reduce((a,b) =>  a + b?.ratingService,0 );
            const _ratingEmployeeSum = ratingResultALL?.data?.reduce((a,b) =>  a + (b?.ratingEmployee || b.ratingService)  ,0);
                

            const _ratingServices = _ratingServiceSum > 0 ? Math.round((_ratingServiceSum  / (ratingResultALL?.data?.length * 5)) * 5 ) : 0;
            const _ratingEmployees = _ratingEmployeeSum > 0 ? Math.round((_ratingEmployeeSum  / (ratingResultALL?.data?.length * 5)) * 5) : 0;
            console.log( '_ratingServices', _ratingServiceSum,ratingResultALL?.data?.length * 5);
            setServicesRate(_ratingServices);
            console.log( '_ratingEmployees', _ratingEmployeeSum ,ratingResultALL?.data?.length * 5);
            setEmployeesRate(_ratingEmployees); 
            console.log('_rate',_ratingServiceSum,_ratingEmployeeSum)
        }
    },[ratingResultALL]);

    return (
        <>
        <div className='h-full gap-3 flex flex-col '>
            {
                servicesResult && ratingResultALL && 
                    <DashboradNavBar
                        servicesLoading={servicesLoading}
                        serviceLength = {servicesResult?.data?.length}
                        ratingLoading = {false}
                        ratingLength = {ratingResultALL?.data?.length}
                        servicesRate = {servicesRate}
                        employeesRate = {employeesRate}
                    />
                }
            <hr className='mb-2 mx-3' />
            <div className='relative px-20 mx-10 '>
                <div className=' flex w-full justify-items-between px-5 absolute top-1  '>
                    {serviceSelected && <div className='flex-1 '>
                        <MwSelector
                            initalValue = {serviceSelected?.serviceName || 'أختار الخدمة....' }
                            dataType={'labelValue'}
                            withAddNew ={false}
                            setSelectedItem={setServiceSelected}
                            selectedItem={serviceSelected}
                            _data={servicesResult?.data?.map(service => ({
                                label:service?.serviceName,
                                value:service,
                            }))}
                            />
                    </div>}
                    <div className='flex-1'></div>
                    <div className='flex-1'></div>
                </div>
                <div className=''>

                    <MonthlyLineChart
                            tenantUsername = {tenantUsername}
                            servicesLoading = {servicesLoading}
                            servicesResult = {servicesResult}
                            serviceSelected = { serviceSelected?.value ? serviceSelected?.value : serviceSelected }
                            ratingByService = {ratingByService}
                            ratingByServiceLoading = {ratingByServiceLoading}
                        />
                </div>
            </div>
        </div>
        </>
    )
}

export default DashBoard