import React from 'react'
import {  MwSpinnerButton, NoDataFound } from '../../components/ui'
import RatingListItem from './RatingListItem'

const RatingList = ({data,loading}) => {
    return (
        <div>
            {
                loading ? 
                    <MwSpinnerButton  isFullCenter={true} withLabel={false} />
                : data.data.length > 0 ?
                    <div className='text-xs'>
                        <div className='flex gap-5 justify-between items-center bg-slate-200 p-3 rounded-t-xl '>
                            <div className='w-8 text-center'>#</div>
                            <div className='w-24 text-center'>تقييم الخدمة</div>
                            <div className='w-24 text-center'>تقييم الموظف</div>
                            <div className='w-24  text-center'> الأسعار مناسبة ؟</div>
                            <div className='w-20  text-center'>رقم الجوال </div>
                            <div className='w-40  text-center'>تاريخ التقييم</div>
                            <div className='w-60'>كيف يمكننا خدمتك بشكل أفضل؟</div>
                            <div className='flex-1'>الشكوي</div>
                        </div>
                        {
                            data.data.map((item,index) => <RatingListItem key={item._id} index={index} item={item} /> )
                        }
                    </div>:
                    <NoDataFound msg={`لا يوجد تقيمات`} />
            }
        </div>
    )
}

export default RatingList