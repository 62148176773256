
import { Routes ,Route } from "react-router-dom";
import withTenant from './utils/withTenant';
import { useState } from "react";
import { useSelector } from "react-redux";

import NotFound from "./pages/NotFound";
import TokenRedirect from "./pages/TokenRedirect";
import Logout from "./pages/Logout";
import DashBoard from "./pages/DashBoard";

import HeaderNavBar from "./layout/HeaderNavBar";
import Footer from "./layout/Footer";
import MenuSide from "./layout/MenuSide";

import withGuard from "./utils/withGuard";
import { useTenant,useStartup } from "./hooks";
import { MwSpinner } from "./components/ui";

import Settings from "./pages/Settings";
import RatingServicesManager from "./pages/rating-services/RatingServicesManager";
import RatingManager from "./pages/rating/RatingManager";
import EditService from "./pages/rating-services/EditService";
import Reports from "./pages/Reports";

function App() {
  const [openMenu,setOpenMenu] = useState(false);
  const langState = useSelector((state) => state.lang);
  const {tenant,tenantUsername,loading:loadingTenant,companiesList,branchesList,tenantId,companySelected,branchSelected} = useTenant();
  const {startup} = useStartup();
  return (
    !loadingTenant && startup ? <div dir={langState?.value === 'ar' ? 'rtl' : 'ltr'} className="h-screen flex flex-col flex-between  ">
      <HeaderNavBar
        companySelected = {companySelected}
        branchSelected = {branchSelected}
        companiesList = {companiesList}
        branchesList = {branchesList}
        tenantUsername = {tenantUsername}
        tenant = {tenant}
        openMenu = {openMenu}
        setOpenMenu = {setOpenMenu}
      />
      <div className="bg-white flex  flex-1   m-auto  w-full">
        <MenuSide
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          />
        <div className={`flex flex-col flex-1 px-2 py-1 pe-3   primary-bg-color ${openMenu ? 'ms-8 md:ms-0 ps-3' :'md:ms-10'} `}>
          <div className="p-5 rounded-2xl bg-slate-50 min-h-[calc(100vh-90px)]">
            <Routes >
                <Route path="/"   element={ <DashBoard/>} />
                <Route path=":tenant/"   element={ <DashBoard/>} />
                <Route path=":tenant/dashboard"   element={ <DashBoard/>} />
                <Route path=":tenant/token/:token" element={<TokenRedirect/>} />
                <Route  path=":tenant/logout"  element={ <Logout/> } />
                <Route  path=":tenant/reports"  element={ <Reports/> } />
                <Route  path=":tenant/settings"  element={ <Settings/> } />
                
                <Route  path=":tenant/rating-services"  element={ <RatingServicesManager/> } />
                <Route  path=":tenant/rating-services/edit/:serviceId"  element={ <EditService/> } />

                <Route  path=":tenant/rating/:serviceId"  element={ <RatingManager/> } />

                <Route path="/notfound"  errorElement={<NotFound/> }  />
                <Route path="/*"  errorElement={<NotFound/> }  />
            </Routes>
          </div>
        </div>
      </div>
      <Footer/>
    </div> : <MwSpinner/>
  );


}

export default withTenant(withGuard(App));
