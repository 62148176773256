import React from 'react'
import RatingBox from '../../components/ui/RatingBox'

const RatingListItem = ({item,index}) => {
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

    const currentDate = new Date(item?.createdAt)
    
    return (
        <div className='flex gap-5 justify-between items-center px-3 py-2 border-b text-slate-600 '>
            <div className='w-8 text-center'>{index+1}</div>
            <div className='w-24 text-center'> <RatingBox rate={item.ratingService}/></div>
            <div className='w-24 text-center'> <RatingBox rate={item.ratingEmployee }/></div>
            <div className='w-24  text-center'> {item.AreThePricesReasonable  || (item.ratingService > 3 && 'نعم') } </div>
            <div className='w-20  text-center'>{item.customerMobile}</div>
            <div className='w-40  text-center'>{currentDate.toLocaleDateString('ar-EG',options)}</div>
            <div className='w-40'>{item.HowCanWeBestServeYou}</div>
            <div className='flex-1'>{item.customerComplaint}</div>
        </div>
    )
}

export default RatingListItem